@import "@/styles/_variables.scss";





































































.size {
    font-size: 2rem;
}
.image-fallback .bi {
    font-weight: 600;
}
